import { AuthAxiosFromData } from "../../global-config/HttpConfig";
import { AuthAxios } from "../../global-config/HttpConfig";
import { ApiURIs } from "../../global-config/ApiList";
import {IUser} from '../models/User';
export default {
  fileUpload(data: FormData) {
    return AuthAxiosFromData.post(ApiURIs.UserFileUpload, data);
  },
  Update(detail: IUser) {
    return AuthAxios.put(ApiURIs.updateUser, JSON.stringify(detail));
  },
  GetAddress() {
    return AuthAxios.get(ApiURIs.GetUserAddress);
  }
  ,
  GetList() {
    return AuthAxios.get(ApiURIs.GetUserList);
  }

};
