

















































































































































































import { Vue, Component } from "vue-property-decorator";
import Header from "../components/Header.vue";
import SideNav from "../components/SideNav.vue";
import ProfileWidget from "../components/ProfileWidget.vue";
import UserList from "./dashboard/UserList.vue";
import DoctorsActivity from "./dashboard/DoctorsActivity.vue";
import BuyAndSell from "./dashboard/BuyAndSell.vue";
import DonorsActivity from "./dashboard/DonorsActivity.vue";
import HospitalList from "./dashboard/HospitalList.vue";
import DocumentOverview from "./dashboard/DocumentOverview.vue";
import { IDoctorDetailsViewModel } from "../../doctor-app/models/DoctorDetailsViewModel";
import DoctorScheduleService from "../../doctor-app/service/DoctorService";
import DashboardService from "../service/DashboardService";

import { IHospitalDetail } from "../../diagnostic-app/models/HospitalDetail";
import HospitalService from "../../diagnostic-app/service/HospitalService";
import UserService from "../../common-app/service/UserService";
import { IDashboardSummary } from "../../admin-app/models/DashboardSummary";

@Component({
  components: {
    Header,
    SideNav,
    ProfileWidget,
    UserList,
    DoctorsActivity,
    BuyAndSell,
    DonorsActivity,
    HospitalList,
    DocumentOverview,
  },
})
export default class AdminLayout extends Vue {
  public sidebarCollapse: boolean = true;
  public allHospital: IHospitalDetail = {} as IHospitalDetail;
  public allDoctor: IDoctorDetailsViewModel[] = [];
  public allUser: any[] = [];
  public dashboardSummaryList: IDashboardSummary[]=[];
  public dashboardSummary: IDashboardSummary= {} as IDashboardSummary;
  public activeDoctor:number =9
  public activeUser:number =0
  public lastWeekNewUser:number =0
  public lastWeekInactiveUser:number =0
  public inActiveUser:number =0
  public pendingDoctor:number =0
  

  sidebarToggleState(state: any) {
    this.sidebarCollapse = state;
    return false;
  }
  created() {
    DoctorScheduleService.GetList().then((s) => {
      this.allDoctor = s.data;
      //console.log(this.allDoctor);
      //this.allDoctor = this.allDoctor.filter((s) => s.isDoctoreActive == 1);
      //console.log(this.allDoctor.length);
    });

    UserService.GetList().then((s) => {
      this.allUser = s.data.result;
      // console.log(this.allUser.length);
    });

    DashboardService.getDashboardSummary().then((s)=>{
       this.dashboardSummaryList=s.data.result;
      
       
       this.dashboardSummaryList.forEach(element => {
        //console.log(element);
        if(element.type =='ActiveUser'){
          this.activeUser=element.total;
        }else if(element.type=='ActiveDoctor'){
          this.activeDoctor=element.total;
        }else if(element.type=='LastWeekNewUser'){
          this.lastWeekNewUser =element.total;
        }
        else if(element.type=='InactiveUser'){
          this.inActiveUser =element.total;
        }
         else if(element.type=='PendingDoctor'){
          this.pendingDoctor =element.total;
        }
        else if(element.type=='LastWeekInactive'){
          this.lastWeekInactiveUser =element.total;
        }
       });
      
      //console.log(this.$data.filter((s)=>s.type==='ActiveDoctor'));
    });
  }
}
